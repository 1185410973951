import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4d9ac630 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1eaddaf6 = () => interopDefault(import('../pages/auth/forgotten.vue' /* webpackChunkName: "pages/auth/forgotten" */))
const _d5398458 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _747e08ba = () => interopDefault(import('../pages/auth/reset.vue' /* webpackChunkName: "pages/auth/reset" */))
const _8c3106e6 = () => interopDefault(import('../pages/auth/welcome.vue' /* webpackChunkName: "pages/auth/welcome" */))
const _4e1c99e1 = () => interopDefault(import('../modules/users/pages/index.vue' /* webpackChunkName: "" */))
const _c5aa2606 = () => interopDefault(import('../modules/users/pages/create.vue' /* webpackChunkName: "" */))
const _0fb76d6a = () => interopDefault(import('../modules/users/pages/edit.vue' /* webpackChunkName: "" */))
const _5e9a8099 = () => interopDefault(import('../modules/stores/pages/index.vue' /* webpackChunkName: "" */))
const _1172ab93 = () => interopDefault(import('../modules/stores/pages/edit.vue' /* webpackChunkName: "" */))
const _75e61594 = () => interopDefault(import('../modules/landingPages/pages/index.vue' /* webpackChunkName: "" */))
const _6e90e7aa = () => interopDefault(import('../modules/landingPages/pages/create.vue' /* webpackChunkName: "" */))
const _218d1f74 = () => interopDefault(import('../modules/landingPages/pages/create/base.vue' /* webpackChunkName: "" */))
const _12dc8200 = () => interopDefault(import('../modules/landingPages/pages/create/final-order-confirmation.vue' /* webpackChunkName: "" */))
const _4e7f8974 = () => interopDefault(import('../modules/landingPages/pages/create/cross-sell.vue' /* webpackChunkName: "" */))
const _1cb72912 = () => interopDefault(import('../modules/landingPages/pages/create/sepa-enhancement.vue' /* webpackChunkName: "" */))
const _4133f0a2 = () => interopDefault(import('../modules/landingPages/pages/create/upsell.vue' /* webpackChunkName: "" */))
const _93671454 = () => interopDefault(import('../modules/landingPages/pages/create/video.vue' /* webpackChunkName: "" */))
const _0ca28e44 = () => interopDefault(import('../modules/landingPages/pages/create/email-signup.vue' /* webpackChunkName: "" */))
const _1d2eb39e = () => interopDefault(import('../modules/landingPages/pages/create/webinar-registration.vue' /* webpackChunkName: "" */))
const _697a17b4 = () => interopDefault(import('../modules/landingPages/pages/create/free-signup.vue' /* webpackChunkName: "" */))
const _34272d3a = () => interopDefault(import('../modules/landingPages/pages/create/multi-offer.vue' /* webpackChunkName: "" */))
const _d627df74 = () => interopDefault(import('../modules/landingPages/pages/create/appointment-booking.vue' /* webpackChunkName: "" */))
const _e70747d6 = () => interopDefault(import('../modules/credentials/pages/index.vue' /* webpackChunkName: "" */))
const _49e234a3 = () => interopDefault(import('../modules/credentials/pages/new.vue' /* webpackChunkName: "" */))
const _02697097 = () => interopDefault(import('../modules/credentials/pages/edit.vue' /* webpackChunkName: "" */))
const _df3d84ac = () => interopDefault(import('../modules/fileManager/pages/index.vue' /* webpackChunkName: "" */))
const _0b86b8a2 = () => interopDefault(import('../modules/tenants/pages/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _4d9ac630,
    name: "index___en"
  }, {
    path: "/auth/forgotten",
    component: _1eaddaf6,
    name: "auth-forgotten___de"
  }, {
    path: "/auth/login",
    component: _d5398458,
    name: "auth-login___de"
  }, {
    path: "/auth/reset",
    component: _747e08ba,
    name: "auth-reset___de"
  }, {
    path: "/auth/welcome",
    component: _8c3106e6,
    name: "auth-welcome___de"
  }, {
    path: "/en/auth/forgotten",
    component: _1eaddaf6,
    name: "auth-forgotten___en"
  }, {
    path: "/en/auth/login",
    component: _d5398458,
    name: "auth-login___en"
  }, {
    path: "/en/auth/reset",
    component: _747e08ba,
    name: "auth-reset___en"
  }, {
    path: "/en/auth/welcome",
    component: _8c3106e6,
    name: "auth-welcome___en"
  }, {
    path: "/",
    component: _4d9ac630,
    name: "index___de"
  }, {
    path: "/users",
    component: _4e1c99e1,
    name: "users___de"
  }, {
    path: "/en/users",
    component: _4e1c99e1,
    name: "users___en"
  }, {
    path: "/users/create",
    component: _c5aa2606,
    name: "users-create___de"
  }, {
    path: "/en/users/create",
    component: _c5aa2606,
    name: "users-create___en"
  }, {
    path: "/users/edit",
    component: _0fb76d6a,
    name: "users-edit___de"
  }, {
    path: "/en/users/edit",
    component: _0fb76d6a,
    name: "users-edit___en"
  }, {
    path: "/stores",
    component: _5e9a8099,
    name: "stores___de"
  }, {
    path: "/en/stores",
    component: _5e9a8099,
    name: "stores___en"
  }, {
    path: "/stores/edit",
    component: _1172ab93,
    name: "stores-edit___de"
  }, {
    path: "/en/stores/edit",
    component: _1172ab93,
    name: "stores-edit___en"
  }, {
    path: "/landingpages",
    component: _75e61594,
    name: "landingpages___de"
  }, {
    path: "/en/landingpages",
    component: _75e61594,
    name: "landingpages___en"
  }, {
    path: "/landingpages/create",
    component: _6e90e7aa,
    name: "landingpages-create___de"
  }, {
    path: "/en/landingpages/create",
    component: _6e90e7aa,
    name: "landingpages-create___en"
  }, {
    path: "/landingpages/create/base",
    component: _218d1f74,
    name: "landingpages-create-base___de"
  }, {
    path: "/en/landingpages/create/base",
    component: _218d1f74,
    name: "landingpages-create-base___en"
  }, {
    path: "/landingpages/create/final-order-confirmation",
    component: _12dc8200,
    name: "landingpages-create-final-order-confirmation___de"
  }, {
    path: "/en/landingpages/create/final-order-confirmation",
    component: _12dc8200,
    name: "landingpages-create-final-order-confirmation___en"
  }, {
    path: "/landingpages/create/cross-sell",
    component: _4e7f8974,
    name: "landingpages-create-cross-sell___de"
  }, {
    path: "/en/landingpages/create/cross-sell",
    component: _4e7f8974,
    name: "landingpages-create-cross-sell___en"
  }, {
    path: "/landingpages/create/sepa-enhancement",
    component: _1cb72912,
    name: "landingpages-create-sepa-enhancement___de"
  }, {
    path: "/en/landingpages/create/sepa-enhancement",
    component: _1cb72912,
    name: "landingpages-create-sepa-enhancement___en"
  }, {
    path: "/landingpages/create/upsell",
    component: _4133f0a2,
    name: "landingpages-create-upsell___de"
  }, {
    path: "/en/landingpages/create/upsell",
    component: _4133f0a2,
    name: "landingpages-create-upsell___en"
  }, {
    path: "/landingpages/create/video",
    component: _93671454,
    name: "landingpages-create-video___de"
  }, {
    path: "/en/landingpages/create/video",
    component: _93671454,
    name: "landingpages-create-video___en"
  }, {
    path: "/landingpages/create/email-signup",
    component: _0ca28e44,
    name: "landingpages-create-email-signup___de"
  }, {
    path: "/en/landingpages/create/email-signup",
    component: _0ca28e44,
    name: "landingpages-create-email-signup___en"
  }, {
    path: "/landingpages/create/webinar-registration",
    component: _1d2eb39e,
    name: "landingpages-create-webinar-registration___de"
  }, {
    path: "/en/landingpages/create/webinar-registration",
    component: _1d2eb39e,
    name: "landingpages-create-webinar-registration___en"
  }, {
    path: "/landingpages/create/free-signup",
    component: _697a17b4,
    name: "landingpages-create-free-signup___de"
  }, {
    path: "/en/landingpages/create/free-signup",
    component: _697a17b4,
    name: "landingpages-create-free-signup___en"
  }, {
    path: "/landingpages/create/multi-offer",
    component: _34272d3a,
    name: "landingpages-create-multi-offer___de"
  }, {
    path: "/en/landingpages/create/multi-offer",
    component: _34272d3a,
    name: "landingpages-create-multi-offer___en"
  }, {
    path: "/landingpages/create/appointment-booking",
    component: _d627df74,
    name: "landingpages-create-appointment-booking___de"
  }, {
    path: "/en/landingpages/create/appointment-booking",
    component: _d627df74,
    name: "landingpages-create-appointment-booking___en"
  }, {
    path: "/credentials",
    component: _e70747d6,
    name: "credentials___de"
  }, {
    path: "/en/credentials",
    component: _e70747d6,
    name: "credentials___en"
  }, {
    path: "/credentials/new",
    component: _49e234a3,
    name: "credentials-new___de"
  }, {
    path: "/en/credentials/new",
    component: _49e234a3,
    name: "credentials-new___en"
  }, {
    path: "/credentials/edit",
    component: _02697097,
    name: "credentials-edit___de"
  }, {
    path: "/en/credentials/edit",
    component: _02697097,
    name: "credentials-edit___en"
  }, {
    path: "/filemanager",
    component: _df3d84ac,
    name: "filemanager___de"
  }, {
    path: "/en/filemanager",
    component: _df3d84ac,
    name: "filemanager___en"
  }, {
    path: "/tenants",
    component: _0b86b8a2,
    name: "tenants___de"
  }, {
    path: "/en/tenants",
    component: _0b86b8a2,
    name: "tenants___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
